.Inspector {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 6px;
  background-color: #3c3c3c;
  box-shadow: 1px 1px 7px 3px #0000002e;
  z-index: 350;
  font-family: 'Nunito', sans-serif;
}

.DragHandle {
  cursor: move;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #282828;;
}

.InspectorBody {
  padding: 8px;
}

.LineItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4px;
}


.LineItemLabel {
  font-weight: 600;
  margin-right: 8px;
  color: #fefefe;
  min-width: 75px;
}

.LineItemValue {
  font-weight: 400;
  color: #43bfa0;
}

.DragIcon {
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
}

.DragText {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 4px;
  text-align: center;
  width: 100%;
}

.LineItemValue.Result {
  color: rgb(193, 125, 101);
}

.LineItemValue.Clickable {
  cursor: pointer;
}

.DetailsPanel {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: none;
  transition: height 0.2s ease-in-out;
}

/* Style scroll bar of ExpandedDetails */
.DetailsPanel::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.DetailsPanel::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.DetailsPanel::-webkit-scrollbar-thumb {
  background: #888;
}

.DetailsPanel::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.DetailsPanel.Visible {
  display: block;
}

.DetailsPanel li[role="treeitem"] {
  margin-bottom: 1px; 
}

.NoData {
  color: #fefefe;
  text-align: center;
}